import {withTranslation} from "react-i18next";
import {HashLink as Link} from 'react-router-hash-link'
import {animateScroll as scroll} from 'react-scroll';
import {useHistory, useLocation} from 'react-router-dom'
import {useEffect, useState} from "react";
import {axiosPrivate} from "../../api/axios";

const LotteryQuickMenu = ({lottery, setSelectedLottery, t}: any) => {
    const navigate = useHistory()
    const location = useLocation()

    const [lotteries, setLotteries] = useState()
    const [message, setMessage] = useState('')
    const [blocking, setBlocking] = useState(true)
    const [token, setToken] = useState('')

    const from = location.state?.from?.pathname || '/'

    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()

        const getLotteries = async () => {
            try {
                setBlocking(true)
                let atkn = localStorage.getItem('atkn')
                setToken(atkn)
                const response = await axiosPrivate.post('lottery/list', {
                    signal: controller.signal,
                })
                setBlocking(false)

                isMounted && setLotteries(response.data?.lotttery)
                if (response.data?.lotttery.length == 0)
                    setMessage('No lotteries to display')
            } catch (err) {
                // console.error(err)
            }
        }

        getLotteries()

        return () => {
            isMounted = false
            controller.abort()
        }
    }, [])

    return (
        <>
            <section className="lottery-quick-menu-section" data-aos="fade-up">
                <div className="container">
                    <div className="lottery-grid">
                        {lotteries && lotteries.map((item) => (
                            <Link
                                key={item.lottery_id}
                                onClick={() => setSelectedLottery(item)}
                            >
                                <div className="inner-wrapper">
                                    <div
                                        className={`logo-box ${
                                            lottery.name === item.name
                                                ? 'selected'
                                                : ''
                                        }`}>
                                        <img
                                            src={item.image_file_path}
                                            className="img-responsive"
                                            alt={`${item.name} Lottery`}
                                            style={{
                                                height: '50px'
                                            }}
                                        />
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(LotteryQuickMenu);