import HomeBanner from '../../components/HomeBanner'
import LotteryGrid from '../../components/LotteryGrid'
import HowItWorks from '../../components/HowItWorks'
import LotteryResult from '../../components/LotteryResult'
import WhyBuyingLottery from '../../components/WhyBuyingLottery'
import OurNextMillionaire from '../../components/OurNextMillionaire'

import FAQ from '../../components/FAQ'
import ReactGA from 'react-ga'
import {useEffect, useState} from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import IPayLotteryGrid from "../../components/IPayLotteryGrid";
import QuickBuyForm from "../../components/QuickBuyForm";
import ScheduleForm from "../../components/ScheduleForm";
import {useHistory, useLocation} from "react-router-dom";
import axios from "../../api/axios";
import {animateScroll as scroll} from "react-scroll/modules";
import useAuth from "../../hooks/useAuth";
import ReactLoading from "react-loading";


const IPayLotteryBuy = ({
                            setQuickOpen,
                            quickOpen,
                            setScheduleOpen,
                            scheduleOpen,
                            setSelectedLottery,
                            selectedLottery,
                            quickBuyLoginOpen,
                            scheduleBuyLoginOpen,
                            setQuickBuyLoginOpen,
                            setScheduleBuyLoginOpen,
                            isLoggedOut
                        }) => {

    const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_CODE
    const WHATSAPP_PHONE_NUMBER = process.env.WHATSAPP_PHONE_NUMBER
    ReactGA.initialize(TRACKING_ID)
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    const [selectedTab, setSelectedTab] = useState(1)
    const [errMsg, setErrMsg] = useState('')
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useHistory()
    const from = location.state?.from?.pathname || '/lottery/buy/pay'
    const {setAuth} = useAuth()

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        const paymentReference = queryParams.get('paymentReference');
        const channel = queryParams.get('channel');


        if (paymentReference !== null) {
            console.log('paymentReference', paymentReference)
            localStorage.setItem('iPayPaymentReference', paymentReference);
        }


        if (token) {
            sendTokenToBackend(token, paymentReference, channel);
        }
    }, [location]);

    useEffect(() => {
        let atkn = localStorage.getItem('atkn')
        if (atkn) {
            setLoading(false);
        }
    }, []);

    const sendTokenToBackend = async (token, paymentReference, channel) => {
        try {
            const response = await axios.post(
                'customer_login/ipay',
                JSON.stringify({token, paymentReference, channel}),
                {
                    headers: {'Content-Type': 'application/json'},
                    // withCredentials: true
                }
            )
            const tkn = response?.data?.token
            if (tkn) {
                const user = response?.data?.user
                const permissions = response?.data?.permissions
                const role = response?.data?.role
                const mobiles = response?.data?.mobiles

                localStorage.setItem('atkn', tkn)
                localStorage.setItem('user', JSON.stringify(user))
                localStorage.setItem('mobiles', JSON.stringify(mobiles))
                localStorage.setItem('permissions', JSON.stringify(permissions))
                localStorage.setItem('role', role)
                setAuth({user, permissions, token, role, mobiles})
                navigate.push(from)
                setLoading(false);
            } else {
                setErrMsg(response.data?.errors?.otp_code)
            }
        } catch (err) {
            console.error('Error verifying token:', err);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        if (quickOpen || scheduleOpen) {
            scrollToTop();
        }
    }, [quickOpen, scheduleOpen]);


    const handleQuickBuyModelClose = () => {
        setQuickOpen(false)
        setQuickBuyLoginOpen(false)
    }

    const handleScheduleModelClose = () => {
        setScheduleOpen(false)
        setScheduleBuyLoginOpen(false)
    }

    if (loading) {
        return <div className='loading-screen'>
            <ReactLoading
                type="spinningBubbles"
                color="#3978e2"
                height={100}
                width={50}
            />
        </div>;
    }

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <section data-aos="fade-up" style={{position: 'absolute', top: '30px'}}>
                    {!quickOpen && !scheduleOpen &&
                        <IPayLotteryGrid
                            setQuickOpen={setQuickOpen}
                            setScheduleOpen={setScheduleOpen}
                            setSelectedLottery={setSelectedLottery}
                            setQuickBuyLoginOpen={setQuickBuyLoginOpen}
                            setScheduleBuyLoginOpen={setScheduleBuyLoginOpen}
                            setSelectedTab={setSelectedTab}
                        />
                    }

                    <div>
                        <>
                            <QuickBuyForm
                                handleClose={handleQuickBuyModelClose}
                                quickOpen={quickOpen}
                                setQuickOpen={setQuickOpen}
                                quickBuyLoginOpen={quickBuyLoginOpen}
                                setSelectedLottery={setSelectedLottery}
                                lottery={selectedLottery}
                                isLoggedOut={isLoggedOut}
                                paymentReference={localStorage.getItem('iPayPaymentReference')}
                            />
                            <ScheduleForm
                                handleClose={handleScheduleModelClose}
                                scheduleOpen={scheduleOpen}
                                scheduleBuyLoginOpen={scheduleBuyLoginOpen}
                                setSelectedLottery={setSelectedLottery}
                                lottery={selectedLottery}
                                isLoggedOut={isLoggedOut}
                                paymentReference={localStorage.getItem('iPayPaymentReference')}
                            />
                        </>
                    </div>
                </section>
                {/*<MessengerCustomerChat*/}
                {/*    pageId="106803611863323"*/}
                {/*    appId="3229410003991004"*/}
                {/*/>*/}
                <WhatsAppWidget
                    phoneNo={WHATSAPP_PHONE_NUMBER}
                    position="right"
                    widgetWidth="300px"
                    widgetWidthMobile="260px"
                    autoOpen={false}
                    // autoOpenTimer={5000}
                    messageBox={true}
                    // messageBoxTxt="Hi Team, is there any related service available ?"
                    iconSize="44"
                    iconColor="white"
                    iconBgColor="green"//rgb(7, 94, 84)
                    // headerIcon="https://proficientdesigners.in/wp-content/themes/pd/img/logo-new.png"
                    headerIconColor="blue"
                    // headerTxtColor="black"
                    // headerBgColor="tomato"
                    // headerTitle="John Doe"
                    headerCaption="Online"
                    // bodyBgColor="#bbb"
                    chatPersonName="811NLB"
                    // chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
                    // footerBgColor="#999"
                    btnBgColor="Green"
                    btnTxtColor="white"
                    btnTxt="Start Chat"
                    // sendButton="Start"
                />
            </div>
        </>
    )
}

export default IPayLotteryBuy