import LotteryBlogSection from '../../components/LotteryDetails';
import LotteryGrid from '../../components/LotteryGrid'
import {useEffect} from "react";
import QuickBuyForm from "../../components/QuickBuyForm";
import ScheduleForm from "../../components/ScheduleForm";
import {withTranslation} from "react-i18next";

const FocusTicket = ({
                         setQuickOpen,
                         quickOpen,
                         setScheduleOpen,
                         scheduleOpen,
                         setSelectedLottery,
                         selectedLottery,
                         quickBuyLoginOpen,
                         scheduleBuyLoginOpen,
                         setQuickBuyLoginOpen,
                         setScheduleBuyLoginOpen,
                         isLoggedOut
                     }) => {

    useEffect(() => {
        if (isLoggedOut === true) {
            setQuickOpen(false);
            setScheduleOpen(false);
            setQuickBuyLoginOpen(false);
            setScheduleBuyLoginOpen(false);
        }
    }, [isLoggedOut]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        scrollToTop();
    }, [quickOpen, scheduleOpen]);


    const handleQuickBuyModelClose = () => {
        setQuickOpen(false)
        setQuickBuyLoginOpen(false)
    }

    const handleScheduleModelClose = () => {
        setScheduleOpen(false)
        setScheduleBuyLoginOpen(false)
    }

    return (
        <>
            {!quickOpen && !scheduleOpen && !quickBuyLoginOpen && !scheduleBuyLoginOpen &&
                <>
                    <LotteryBlogSection
                        subscribe_and_buy="Subscribe & Buy"
                        buy_now="Buy Now"
                        setQuickOpen={setQuickOpen}
                        setScheduleOpen={setScheduleOpen}
                        setSelectedLottery={setSelectedLottery}
                        setQuickBuyLoginOpen={setQuickBuyLoginOpen}
                        setScheduleBuyLoginOpen={setScheduleBuyLoginOpen}
                    />
                    <LotteryGrid
                        title="Lotteries Header"
                        sub_title="Lotteries Sub Header"
                        next_price="Next Super Price"
                        sold_out="Sold Out"
                        subscribe="Subscribe"
                        schedule="Schedule"
                        subscribe_and_buy="Subscribe & Buy"
                        buy_now="Buy Now"
                        draw_no="Draw No"
                        draw_date="Draw Date"
                        draw_close="Draw Close"
                        setQuickOpen={setQuickOpen}
                        setScheduleOpen={setScheduleOpen}
                        setSelectedLottery={setSelectedLottery}
                        setQuickBuyLoginOpen={setQuickBuyLoginOpen}
                        setScheduleBuyLoginOpen={setScheduleBuyLoginOpen}
                    />
                </>
            }

            <>
                <QuickBuyForm
                    handleClose={handleQuickBuyModelClose}
                    quickOpen={quickOpen}
                    setQuickOpen={setQuickOpen}
                    quickBuyLoginOpen={quickBuyLoginOpen}
                    lottery={selectedLottery}
                    setSelectedLottery={setSelectedLottery}
                    isLoggedOut={isLoggedOut}
                />
                <ScheduleForm
                    handleClose={handleScheduleModelClose}
                    scheduleOpen={scheduleOpen}
                    setScheduleOpen={setScheduleOpen}
                    scheduleBuyLoginOpen={scheduleBuyLoginOpen}
                    lottery={selectedLottery}
                    setSelectedLottery={setSelectedLottery}
                    isLoggedOut={isLoggedOut}
                />
            </>
        </>
    )
}

export default withTranslation()(FocusTicket);