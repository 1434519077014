import { withTranslation } from "react-i18next";

import LogoSlickSlider from "react-slick";
import { HashLink as Link } from 'react-router-hash-link'
// import { animateScroll as scroll } from 'react-scroll';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { useHistory, useLocation } from 'react-router-dom'
import CollectSpecial from '../../components/CollectSpecial'
import {useEffect, useState} from "react";
import {axiosPrivate} from "../../api/axios";
const LogoSlider = ({ t }: any) => {
   const navigate = useHistory()
   const location = useLocation()
   // const [showComponentA, setShowComponentA] = useState(true);

   const [lotteries, setLotteries] = useState()
   const [message, setMessage] = useState('')
   const [blocking, setBlocking] = useState(true)
   const [token, setToken] = useState('')

   const from = location.state?.from?.pathname || '/'
   var settings = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 7,
      autoplay: true,
      arrows: false,
      autoplaySpeed: 2000,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 6,
               slidesToScroll: 1,
               infinite: true,
               dots: true,
            },
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            },
         },

      ],
   };
   const handleScroll = () => {

      navigate.push(from)
      // scroll.scrollTo(1450);
      const isMobile = window.innerWidth <= 767;
      const isTablet = window.innerWidth > 767 && window.innerWidth <= 1024;

      navigate.push(from);

      if (isMobile) {
         scroll.scrollTo(3500);
      } else if (isTablet) {
         scroll.scrollTo(1900);
      } else {
         scroll.scrollTo(1250);
      }
   }

   useEffect(() => {
      let isMounted = true
      const controller = new AbortController()

      const getLotteries = async () => {
         try {
            setBlocking(true)
            let atkn = localStorage.getItem('atkn')
            setToken(atkn)
            const response = await axiosPrivate.post('lottery/list', {
               signal: controller.signal,
            })
            setBlocking(false)

            isMounted && setLotteries(response.data?.lotttery)
            if (response.data?.lotttery.length == 0)
               setMessage('No lotteries to display')
         } catch (err) {
            // console.error(err)
         }
      }

      getLotteries()

      return () => {
         isMounted = false
         controller.abort()
      }
   }, [])

   return (
      <>

         <section className="logo-slider-section" data-aos="fade-up">
            <div className="container">
               

               <LogoSlickSlider className="logo-slider"  {...settings}>
                  {lotteries && lotteries.map((lottery) => (
                      <Link to={`/ln?tk=${lottery.code.toLowerCase()}`} key={lottery.lottery_id}>
                         <div className="inner-wrapper">
                            <div className="logo-box">
                               <img
                                   src={lottery.image_file_path}
                                   className="img-responsive"
                                   alt={lottery.name}
                                   style={{ height: '50px' }} // Adjust height as needed
                               />
                            </div>
                         </div>
                      </Link>
                  ))}
               </LogoSlickSlider>

            </div>


            {/* <div className="boxbdr">
               <div className="digital" >
                  <div className="boxes " >
                     <div className="box box-one" >
                        <div className="animation">
                           <Link to="/ln?tk=mm">
                              <div className="inner-wrapper">
                                 <div className="logo-box" >

                                    <img className="img-fluid" style={{ height: '75px', marginTop: "10px" }} src={require('../../assets/images/home/logo-slider/click_here.png')} className="img-responsive" alt="Image" />
                                 </div>
                              </div>
                           </Link> */}

            {/* <span>To Win Your Mega Millions!!  Be Rich 🤩</span> */}
            {/* <Link to="/ln?tk=mm">
                  <div className="inner-wrapper">
                     <div className="logo-box" >
                      
                     <img style={{height:'50px'}}  src={require('../../assets/images/home/logo-slider/click_here.png')} className="img-responsive" alt="Image" />
                     </div>
                  </div>
                  </Link> */}
            {/* 
                        </div>
                     </div>


                  </div>
               </div>
            </div> */}



         </section>

      </>
   );
};

export default    withTranslation()(LogoSlider);